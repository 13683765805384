import './App.css';
import React, {useState, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import AppNavigator from './router';
import './fonts/BAHNSCHRIFT 10.TTF';

function App() {

  const [userType, setUsertype] = React.useState('')
  const [branchId, setBranchid] = React.useState('')
  const [comapanyId, setCompanyId] = React.useState('')
  const [userId, setUserid] = React.useState('')

  // useEffect(() => {
  //   var userdata = JSON.parse(localStorage.getItem("user"))
  //   console.log("userdata", userdata)
  //   if(userdata != null){
  //     setBranchid(userdata.user_branch_id)
  //     setCompanyId(userdata.user_company_id)
  //     setUserid(userdata.user_id)
  //     if(userdata.user_type == '0'){
  //       setUsertype(userdata.user_type)
  //     }
  //     else if(userdata.user_type == '1'){
  //       setUsertype(userdata.user_type)
  //     }
  //     else{
  //       setUsertype("superadmin")
  //     }
  //     console.log("userType", userType)
  //   }
   
  // },[userType])
  
  
  // global.url = "https://arora.nascaffe.com/erp/public/api/";
  // global.images = "https://arora.nascaffe.com/erp/public/";
  // global.excell = "https://arora.nascaffe.com/erp/storage/app/";

  // global.url = "http://nasonline.nascaffe.com/erp/public/api/";
  // global.images = "http://nasonline.nascaffe.com/erp/public/";
  // global.excell = "http://nasonline.nascaffe.com/erp/storage/app/";

  // global.url = "http://erp.nasshopy.com/erp/public/api/";
  // global.images = "http://erp.nasshopy.com/erp/public/";
  // global.excell = "http://erp.nasshopy.com/erp/storage/app/";

  // global.url = "http://nas.nasscript.com/erp/public/api/";
  // global.images = "http://nas.nasscript.com/erp/public/";
  // global.excell = "http://nas.nasscript.com/erp/storage/app/";

  // global.url = "https://erp.fetch.qa/erp/public/api/";    //live url
  // global.images = "https://erp.fetch.qa/erp/public/";
  // global.excell = "https://erp.fetch.qa/erp/storage/app/";

  // global.url = "http://erpdemo.fetch.qa/erp/public/api/";
  // global.images = "http://erpdemo.fetch.qa/erp/public/";
  // global.excell = "http://erpdemo.fetch.qa/erp/storage/app/";
 
  // global.url = "http://192.168.18.30/erp/public/api/";
  // global.images = "http://192.168.18.30/erp/public/";
  // global.excell = "http://192.168.18.30/erp/storage/app/";

  // global.url = "http://fetcherp.nascaffe.com/erp/public/api/";
  // global.images = "http://fetcherp.nascaffe.com/erp/public/";
  // global.excell = "http://fetcherp.nascaffe.com/erp/storage/app/";

  // global.url = "https://unitdemo.nasscript.com/erp/public/api/";
  // global.images = "https://unitdemo.nasscript.com/erp/public/";
  // global.excell = "https://unitdemo.nasscript.com/erp/storage/app/";

  // global.url = "http://192.168.18.79:88/erp/public/api/";
  // global.images = "http://192.168.18.79:88/erp/public/";
  // global.excell = "http://192.168.18.79:88/erp/storage/app/";

  //   global.url = "https://daknath.nasscript.com/erp/public/api/";
  // global.images = "https://daknath.nasscript.com/erp/public/";
  // global.excell = "https://daknath.nasscript.com/erp/storage/app/";

  // global.url = "https://unitsample.nasscript.com/erp/public/api/";
  // global.images = "https://unitsample.nasscript.com/erp/public/";
  // global.excell = "https://unitsample.nasscript.com/erp/storage/app/";

  global.url = "https://qbms.nasscript.com/erp/public/api/";
  global.images = "https://qbms.nasscript.com/erp/public/";
  global.excell = "https://qbms.nasscript.com/erp/storage/app/";

  // global.url = "http://nasqa.nasscript.com/erp/public/api/";
  // global.images = "http://nasqa.nasscript.com/erp/public/";
  // global.excell = "http://nasqa.nasscript.com/erp/storage/app/";

  // global.url = "http://gana.nascaffe.com/erp/public/api/";
  // global.images = "http://gana.nascaffe.com/erp/public/";
  // global.excell = "http://gana.nascaffe.com/erp/storage/app/";

  // global.url = "http://localhost/erp/public/api/";
  // global.images = "http://localhost/erp/public/";
  // global.excell = "http://localhost/erp/storage/app/";

  // global.url = "http://192.168.18.79:88/nasshopy_barckend/public/api/";
  // global.images = "http://192.168.18.79:88/nasshopy_barckend/public/api/";
  // global.excell = "http://192.168.18.79:88/nasshopy_barckend/public/api/";

  // global.url = "http://erp.nasscript.com/erp/public/api/";
  // global.images = "http://erp.nasscript.com/erp/public/";
  // global.excell = "http://erp.nasscript.com/erp/storage/app/";

  // global.url = "http://erp.loopshopy.com/erp/public/api/";
  // global.images = "http://erp.loopshopy.com/erp/public/";
  // global.excell = "http://erp.loopshopy.com/erp/storage/app/";
  // global.type = userType
  // global.branch = branchId
  // global.company = comapanyId
  // global.userid = userId

  global.proname = "Fetch"
  global.website = "www.fetch.qa"
  // global.proname = "Nasshopy"
  // global.website = "www.nasshopy.com"
  

  return (
      <AppNavigator />
  );
}

export default App;
